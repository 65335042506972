<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Litter Sale</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> List </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-card no-body>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Date picker -->
					<b-col cols="12" md="6">
						<b-row>
							<b-col md="5">
								<label for="example-input">From Date</label>
								<b-form-datepicker
									id="from-date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.from_date"
								/>
							</b-col>
							<b-col md="5">
								<label for="example-input">To Date</label>
								<b-form-datepicker
									id="to-date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.to_date"
								/>
							</b-col>
							<b-col md="2 d-flex align-items-end">
								<b-button @click="getLitterSale()" variant="primary "> Filter </b-button>
							</b-col>
						</b-row>
					</b-col>
					<!-- Search -->
					<b-col cols="12" md="6" class="mt-2 d-flex justify-content-end align-items-center">
						<div>
							<b-form-input
								type="text"
								class="form-control"
								placeholder="Search"
								@input="getLitterSale()"
								v-model.trim="search_filter.input_field"
								style="border-radius: 4px"
							/>
						</div>
						<b-button
							@click="clearFilter"
							variant="secondary
 			 ml-1"
						>
							Clear
						</b-button>
						<b-button v-b-modal.litter-sale variant="primary mr-1 ml-1"> Add </b-button>
						<b-button @click="excelDownload()" variant="warning">
							<feather-icon icon="DownloadIcon" size="16" />
						</b-button>
					</b-col>
				</b-row>
			</div>
			<div v-if="litterSale && litterSale.total > 0">
				<b-table
					:items="litterSale.data"
					responsive
					:fields="fields"
					show-empty
					empty-text="No matching records found"
					class="position-relative"
				>
					<template #cell(index)="data">
						<div class="ml-1">
							{{ litterSale.from + data.index }}
						</div>
					</template>
				</b-table>

				<div class="mx-2 mb-2">
					<b-pagination
						:value="litterSale.current_page"
						:total-rows="litterSale.total"
						:per-page="litterSale.per_page"
						align="right"
						@change="getLitterSale"
					>
					</b-pagination>
				</div>
			</div>
			<div v-else class="d-flex flex-column align-items-center py-5">
				<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
				<h4 class="mt-2 Secondary">No litter sold yet</h4>
			</div>
			<!-- hatch mortality modal -->
			<b-modal id="litter-sale" title="Litter Sale" ok-only @ok="addLitterSale" ok-title="Submit" ref="litter-sale">
				<validation-observer ref="simpleRules">
					<form ref="form">
						<b-form-group>
							<template v-slot:label> Sale Date <span class="text-danger">*</span> </template>
							<div class="form-label-group">
								<validation-provider #default="{ errors }" name="Sale Date" rules="required">
									<b-form-datepicker v-model="value" placeholder="Choose a date" local="en" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</div>
						</b-form-group>
						<b-form-group>
							<template v-slot:label> Flock ID<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Flock ID" rules="required">
								<v-select
									v-model="FormData.flock_id"
									:clearable="false"
									label="flock_id"
									:options="flockDataList"
									:reduce="(e) => e.flock_id"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group>
							<template v-slot:label> Item Sold<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Item Sold" rules="required">
								<v-select
									v-model="FormData.type"
									:options="options"
									label="item"
									item-value="value"
									:reduce="(e) => e.value"
								></v-select>
								<!-- <b-form-input name="quantity" v-model="FormData.quantity" placeholder="Item Sold" /> -->
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<!-- <b-form-group>
							<template v-slot:label> Total Sale cost (Rs.)<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Total cost" rules="required">
								<b-form-input name="weight" v-model="FormData.weight" placeholder="Total cost" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group> -->
						<b-form-group>
							<template v-slot:label>Total Price<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Price" rules="required">
								<b-form-input name="total_price" v-model="FormData.total_price" placeholder="Total Price" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group>
							<template v-slot:label> Sold To<span class="text-danger">*</span> </template>
							<div class="form-label-group">
								<validation-provider #default="{ errors }" name=" Source" rules="required">
									<v-select
										v-model="FormData.source_id"
										:options="sourceList"
										label="name"
										clearable:false
										:reduce="(e) => e.id"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</div>
						</b-form-group>
					</form>
				</validation-observer>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useChickSaleList from "./useChickSaleList";
import saleStoreModules from "../saleStoreModules";
import axiosIns from "@/libs/axios";
import moment from "moment";
import { BASE_URL } from "@core/common/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			required,
			selected: null,
			FormData: {
				quantity: null,
				unit_cost: null,
				weight: null,
				buyer: null,
			},
			value: "",
			search: null,
			rows: null,
			fields: [
				{ key: "index", label: "s.no" },
				{ key: `sale_type`, label: "sale type" },
				// { key: `quantity`, label: "sold num chick" },
				// { key: `unit_cost`, label: "litter rate" },
				{ key: `source.name`, label: "buyer" },
				{ key: `total_amount`, label: "amount" },
				{
					key: "created_at",
					label: "sell date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				// "Actions",
			],
			litterSale: [],
			flockDataList: [],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			sourceList: null,
			options: [
				{ item: "Litter", value: "litter" },
				{ item: "Egg Tray", value: "egg_tray" },
				{ item: "Gunny Bags", value: "gunny_bags" },
				{ item: "Crack Eggs", value: "crack_eggs" },
				{ item: "Cull birds", value: "cull_birds" },
			],
		};
	},
	methods: {
		getLitterSale(pageNo = 1) {
			const farmId = this.$route.params.farmId;
			const saleType = "litter";
			let url = `web/farm/${farmId}/sale_type/${saleType}/getSaleInformation`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.litterSale = response.data.litter_saleInformationAll;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFlockList() {
			const farmId = this.$route.params.farmId;
			axiosIns
				.get(`web/farm/${farmId}/flock-list`)
				.then((response) => {
					this.flockDataList = response.data.flock_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		addLitterSale(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					const farmId = this.$route.params.farmId;
					const flockId = this.FormData.flock_id;
					var data = new FormData();
					data.append("sale_type", this.FormData.type);
					data.append("source_id", this.FormData.source_id);
					// data.append("weight", this.FormData.weight);
					data.append("total_price", this.FormData.total_price);
					// data.append("quantity", this.FormData.quantity);
					axiosIns
						.post(`web/farm/${farmId}/flock/${flockId}/sales`, data)
						.then((res) => {
							res;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$refs["litter-sale"].hide();
							this.getLitterSale();
						})

						.catch((error) => {
							error;
						});
				}
			});
		},

		excelDownload() {
			const saleType = "litter";
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-post-litter-sale/${saleType}`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getSourceList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getSourceList();
				});
		},

		getSource() {
			axiosIns
				.get(`web/get-all-sources`)
				.then((response) => {
					this.sourceList = response.data.source_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getLitterSale();
		},
	},
	created() {
		this.farmId = this.$route.params.farmId;
		this.getLitterSale();
		this.getFlockList();
		this.getSource();
	},
	setup() {
		const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

		// Register module
		if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
			store.registerModule(INVOICE_APP_STORE_MODULE_NAME, saleStoreModules);

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
		});

		const statusOptions = ["Downloaded", "Draft", "Paid", "Partial Payment", "Past Due"];

		const {
			fetchInvoices,
			saleTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,
			statusFilter,
			refetchData,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		} = useChickSaleList();

		return {
			fetchInvoices,
			saleTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,

			statusFilter,

			refetchData,

			statusOptions,

			avatarText,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		};
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
